import React, { useEffect, useState } from "react";
import { Carousel } from "antd";

import HeartIcon from "../../assets/splashes/HeartIcon";
import { BlogType } from "../../models/Blog";
import { getStoryblokData } from "../../utils/getStoryblokData";
import BlogTeaser from "../blogs/BlogTeaser";
const BlogSection = () => {


  const [groupedBlogs, setGroupedBlogs] = useState<
    ({ slug: string; content: BlogType }[] | undefined)[]
  >([]);

  useEffect(() => {
    const getBlogs = async () => {
      const { data: blogData } = await getStoryblokData<{
        stories: { slug: string; content: BlogType }[];
      }>("cdn/stories/", "blog");

      // Group blogs into sublists of two
      const groupedBlogs = [];
      for (let i = 0; i < blogData?.stories.length!; i += 2) {
        groupedBlogs.push(blogData?.stories.slice(i, i + 2));
      }

      setGroupedBlogs(groupedBlogs);
    };
    getBlogs();
  }, []);

  return (
    <section className="home-page__blog-section">
      <HeartIcon classNames={"home-page__blog-section-heart"}  bgColor="#e22758" />
      <Carousel autoplay arrows dots>
        {groupedBlogs.map((blog, index) => (
          <div key={index}>
            <div className="blog-section__blog-slide">
              {blog?.map((blogPost) => (
                <BlogTeaser blog={blogPost} key={blogPost.slug} />
              ))}
            </div>
          </div>
        ))}
      </Carousel>
    </section>
  );
};

export default BlogSection;
