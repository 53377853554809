import instagramIcon from "../../assets/svgs/social/instagram.svg";
import twitterIcon from "../../assets/svgs/social/twitter.svg";
import tiktokIcon from "../../assets/svgs/social/tiktok.svg";
import whatsappIcon from "../../assets/svgs/social/whatsapp.svg";
import youtubeIcon from "../../assets/svgs/social/youtube.svg";

type SocialMediaLink = {
  name: string;
  link: string;
  icon: string;
};

export const socialMediaLinks: SocialMediaLink[] = [
  {
    name: "Instagram",
    link: "https://www.instagram.com/maha_binsahl/",
    icon: String(instagramIcon),
  },
  {
    name: "WhatsApp",
    link: "https://wa.link/zij507",
    icon: String(whatsappIcon),
  },
  {
    name: "TikTok",
    link: "https://www.tiktok.com/@maha_binsahl?is_from_webapp=1&sender_device=pc",
    icon: String(tiktokIcon),
  },
  {
    name: "Twitter",
    link: "https://twitter.com/Maha_BinSahl",
    icon: String(twitterIcon),
  },
  {
    name: "YouTube",
    link: "https://www.youtube.com/@MahaBinSahl",
    icon: String(youtubeIcon),
  },
];
