import React from "react";
import ShopHeader from "../../assets/splashes/ShopHeader";
import SelectSquare from "../../assets/splashes/SelectSquare";
import CursorClicked from "../../assets/splashes/CursorClicked";
import SandWatch from "../../assets/splashes/SandWatch";
import Button from "../button/Button";
import ShopHeaderMobile from "../../assets/splashes/ShopHeaderMobile";

const ShopHero = () => {
  return (
    <div className="shop-page__hero">
      <div className="shop-page__hero-left">
        <SandWatch classnames="shop-page__hero-sand-watch" />
        <a href="#shop">
          <Button black text="check it out" type="button" />
        </a>
      </div>
      <div className="shop-page__hero-content">
        <ShopHeaderMobile classNames="shop-page__hero-header-mobile" />
        <ShopHeader classNames="shop-page__hero-header" />
        <SelectSquare classNames="shop-page__hero-select-square" />
        <CursorClicked classNames="shop-page__hero-cursor-clicked" />
      </div>
    </div>
  );
};

export default ShopHero;
