import React from "react";

const LandBush = ({clasNames}) => {
  return (
    <div className={clasNames} >
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 378 342">
        <path
          fill="#FC7E29"
          stroke="#191E32"
          strokeMiterlimit="10"
          strokeWidth="4.3"
          d="M5 375h416V14a71 71 0 0 0-71-4 68 68 0 0 0-36 60c-19-3-38 15-36 34-23-15-50-23-77-19-26 4-52 21-63 45-12 24-9 56 10 75-16-4-36 6-47 18a47 47 0 0 0-10 45c-15 3-25 21-19 35-19-4-40 2-53 16s-21 37-14 56Z"
        />
        <path
          fill="#E22758"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="4.3"
          d="M81 451h416V90a71 71 0 0 0-71-4 68 68 0 0 0-35 60c-20-3-39 15-37 35-22-15-50-23-76-19-27 3-52 20-64 44s-8 56 10 76c-16-4-36 5-47 17a47 47 0 0 0-10 45c-15 3-25 22-19 36-18-5-40 1-53 16-13 14-20 36-14 55Z"
        />
      </svg>
    </div>
  );
};

export default LandBush;
