import React from "react";

const LandWaves = ({ classnames }) => {
  return (
    <div className={classnames}>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 650 600">
        <path
          fill="#E22758"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="4.3"
          d="M630-93H3C-2-41 21 13 63 45c46 35 111 45 145 91 23 32 26 73 32 111s18 80 51 101c47 30 119 4 162 40 49 41 25 131 72 173 27 24 67 24 103 23l1-79V243l1-118V-93Z"
        />
        <path
          fill="#FF5052"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="4.3"
          d="M630-93H90c10 26 27 49 49 66 46 35 111 45 145 92 23 31 26 72 32 110 6 39 18 80 51 101 48 31 119 4 162 40 49 41 25 131 72 174 9 7 18 12 28 15V243l1-118V-93Z"
        />
        <path
          fill="#FC7E29"
          stroke="#191E32"
          strokeMiterlimit="10"
          strokeWidth="4.3"
          d="M630-93H258c41 22 88 36 116 73 23 32 26 73 32 111 5 38 18 80 50 101 48 30 119 3 163 40l10 11 1-118V-93Z"
        />
        <path
          fill="#E22758"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="4.3"
          d="M630-93H470c17 29 20 66 25 100 6 38 18 80 51 100 24 16 54 16 84 18V-93Z"
        />
      </svg>
    </div>
  );
};

export default LandWaves;
