import React, { useState, useContext, useEffect } from "react";
import Modal from "../modal/Modal";
import { PostType } from "../../models/Post";
import Comments from "./../comments/Comments";
import { CommentType } from "../../models/Comment";
import { apiurl } from "../../App";
import { UserContext } from "../../context/UserContext";

import { MessageContext } from "../../context/MessageContext";
import axios from "axios";
import { LikeType } from "../../models/Like";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { Heart, MessageCircleMore, X } from "lucide-react";
interface Props {
  isOpen: boolean;
  onClose: () => void;
  post: PostType;
}
const BreathPostPreview = ({ isOpen, onClose, post }: Props) => {
  const [comments, setComments] = useState<CommentType[]>([]);
  const [likes, setLikes] = useState<LikeType[]>([]);

  const { user, isAuth } = useContext(UserContext);
  const { onError } = useContext(MessageContext);

  const inputRef = React.useRef<HTMLInputElement>(null);

  const navigate = useNavigate();

  const isLiked = likes.find((like) => like.user_id === user?.id);

  const likePost = () => {
    if (!isAuth) {
      navigate("/sign");
      return;
    }

    if (!isLiked) {
      // Optimistic UI update
      const tempLike = {
        id: Date.now().toString(),
        user_id: user.id,
        post_id: post.id,
      };
      setLikes((prevLikes) => [...prevLikes, tempLike]);

      axios
        .post(apiurl + "/likes", { user_id: user.id, post_id: post.id })
        .then((res) => {
          if (res.status === 201) {
            setLikes((prevLikes) =>
              prevLikes.map((like) =>
                like.id === tempLike.id ? res.data.like : like
              )
            );
          }
        })
        .catch(() => {
          setLikes((prevLikes) =>
            prevLikes.filter((like) => like.id !== tempLike.id)
          );
          onError("Failed to like");
        });
    } else {
      const like = likes.find((like) => like.user_id === user.id);
      if (!like) return;

      // Optimistic UI update
      setLikes((prevLikes) => prevLikes.filter((l) => l.id !== like.id));

      axios
        .post(`${apiurl}/delete-like/${like.id}`)
        .then((res) => {
          if (res.status !== 200) {
            throw new Error("Failed to unlike");
          }
        })
        .catch(() => {
          setLikes((prevLikes) => [...prevLikes, like]);
          onError("Failed to unlike");
        });
    }
  };

  useEffect(() => {
    fetch(apiurl + `/comments/${post.id}`)
      .then((res) => res.json())
      .then((data) => {
        setComments(data);
      })
      .catch((err) => console.log(err));

    fetch(apiurl + "/likes/" + post.id)
      .then((res) => res.json())
      .then((data) => setLikes(data))
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="breath-post-preview">
      <Modal isOpen={isOpen} onClose={onClose} theme="white">
        <div className="breath-post-preview__body">
          <X
            onClick={onClose}
            size={46}
            color="#e22758"
            className="breath-post-preview__close-btn"
          />
          <img
            className="breath-post-preview__image"
            src={post.image}
            alt="post"
          />
          <div className="breath-post__info">
            <div className="breath-post__data">
              <p
                dir={"ltr"}
                className="breath-post__description"
                dangerouslySetInnerHTML={{ __html: post.description }}
              />
              <span className="breath-post__icons">
                <Heart
                  fill={isLiked ? "#e22758" : 'transparent'}
                  onClick={likePost}
                  style={{ cursor: "pointer" }}
                ></Heart>
                <MessageCircleMore />
              </span>
              <p className="breath-post__likes">{likes.length} Likes</p>
            </div>

            <Comments
              inputRef={inputRef}
              comments={comments}
              contentId={post.id}
              replyEndPoint="/reply-post"
              endPoint="/comments"
              isAuth={isAuth}
              updateContent={setComments}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default BreathPostPreview;
