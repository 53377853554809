import React, { createContext, useEffect, useState } from "react";
import axios from "axios";

import { apiurl } from "../App";
import { UserType } from "../models/User";

type UserContextType = {
  user: UserType;
  setUser: (user: any) => void;
  isAuth: boolean;
};

export const UserContext = createContext<UserContextType>({
  user: {
    id: "string",
    username: "string",
    avatar: "string",
    phoneNumber: "string",
    email: "string",
    cart: [],
  },
  setUser: (_data: any) => {},
  isAuth: false,
});

const UserContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState({
    id: "",
    username: "",
    avatar: "guest",
    email: "",
    phoneNumber: "",
    cart: [],
  });

  const [isAuth, setIsAuth] = useState<boolean>(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      axios
        .post(apiurl + "/isAuth", {
          token,
        })
        .then((res) => {
          const { data } = res;

          setUser({ ...data, cart: [] });
          setIsAuth(true);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [isAuth]);
  return (
    <UserContext.Provider value={{ user, setUser, isAuth }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
