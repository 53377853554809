import React, { FormEvent, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import Input from "../form/input/Input";
import { apiurl } from "../../App";
import Button from "../button/Button";
import { UserContext } from "../../context/UserContext";

interface Props {
  onError: (_message: any) => void;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLogin: React.Dispatch<React.SetStateAction<boolean>>;
}

const LoginCard = ({ setIsLogin, onError, setLoading }: Props) => {
  const [loginForm, setLoginForm] = useState({
    email: "",
    password: "",
  });

  const { setUser } = useContext(UserContext);
  const navigate = useNavigate();

  const onLogin = (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    fetch(apiurl + "/login", {
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(loginForm),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          onError(data.error);
          setLoading(false);
        } else {
          setUser(data);

          localStorage.setItem("token", data.id);
          localStorage.setItem("email", data.email);

          navigate("/");
        }
      })
      .catch((err) => {
        console.error(err);
        onError("Something went wrong");
      });
  };

  return (
    <div className="login-card">
      <h1 className="login-card__head">Login</h1>
      <p className="login-card__message">
        Dont have an account?
        <span onClick={() => setIsLogin((prev) => !prev)}>Register</span>
      </p>
      <form onSubmit={onLogin} className="login-card__form">
        <Input
          name="email"
          value={loginForm.email}
          onChange={(e) =>
            setLoginForm({ ...loginForm, email: e.target.value })
          }
          isRequired
          type="email"
          placeholder="Email"
        />
        <Input
          type="password"
          name="password"
          placeholder="Password"
          value={loginForm.password}
          onChange={(e) =>
            setLoginForm({ ...loginForm, password: e.target.value })
          }
          isRequired
        />
        <Button type="submit" pink text="login" />
      </form>
    </div>
  );
};

export default LoginCard;
