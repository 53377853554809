import classNames from "classnames";
import React from "react";

interface Props {
  text: React.ReactNode;
  onClick?: () => void;
  orange?: boolean;
  pink?: boolean;
  black?: boolean;
  transparent?: boolean;
  lightPink?: boolean;
  type: "button" | "reset" | "submit";
  disabled?: boolean;
  size?: "x-small" | "small" | "medium" | "large";
  isFullWidth?: boolean;
}
const Button = ({
  disabled,
  onClick,
  text,
  type,
  lightPink,
  transparent,
  orange,
  isFullWidth,
  black,
  pink,
  size = "medium",
}: Props) => {
  return (
    <button
      className={classNames("mx-button", {
        "mx-button--orange": orange,
        "mx-button--pink": pink,
        "mx-button--black": black,
        "mx-button--light-pink": lightPink,
        "mx-button--transparent": transparent,
        "mx-button--full-width": isFullWidth,
        [`mx-button--${size}`]: size,
      })}
      onClick={onClick}
      type={type}
      disabled={disabled}
    >
      {text}
    </button>
  );
};

export default Button;
