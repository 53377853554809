import React from "react";

import Star from "../../assets/splashes/Star";
import GalleryHeaderTabs from "../../assets/splashes/GalleryHeaderTabs";
import Button from "../button/Button";

const GalleryHero = () => {
  return (
    <div className="gallery-page__hero">
      <div className="gallery-page__hero-image">
        <GalleryHeaderTabs classNames="gallery-page__hero-tabs" />
      </div>
      <div className="gallery-page__hero-content">
        <h1 className="gallery-page__hero-title">
          <span>Take </span> a breath and share your <span>Art</span>
        </h1>
        <h3 className="gallery-page__hero-subtitle">
          by joining our community
        </h3>
        <Button
          text="Join us now"
          black
          type="button"
          onClick={() => (window.location.href = "https://t.me/SuperDesiners")}
        />
        <Star classNames="gallery-page__hero-star" color="#e22758" />
      </div>
    </div>
  );
};

export default GalleryHero;
