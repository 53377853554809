import React, { FormEvent, useContext, useState } from "react";
import Modal from "../modal/Modal";
import Button from "../button/Button";
import { apiurl } from "../../App";
import { UserContext } from "../../context/UserContext";

import { MessageContext } from "../../context/MessageContext";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { PostType } from "../../models/Post";
import RichTextEditor, { ToolbarConfig } from "react-rte";
import Loading from "../loading/Loading";
import { toolbarConfig } from "../../utils/richtextConfig";

type UploadDataType = {
  image: string | ArrayBuffer | null;
  description: string;
};
interface Props {
  isOpen: boolean;
  onClose: () => void;
  setPosts: React.Dispatch<React.SetStateAction<PostType[]>>;
}
const UploadBreath = ({ isOpen, onClose, setPosts }: Props) => {
  const [loading, setLoading] = useState(false);
  const [uploadData, setUploadData] = useState<UploadDataType>({
    image: "",
    description: "",
  });

  const [editorValue, setEditorValue] = React.useState(
    RichTextEditor.createValueFromString(uploadData.description, "html")
  );

  const handleChange = (value: any) => {
    setEditorValue(value);
    setUploadData({ ...uploadData, description: value.toString("html") });
  };
  const { user, isAuth } = useContext(UserContext);
  const { onError, onSuccess } = useContext(MessageContext);

  const navigate = useNavigate();

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files![0];
    const reader = new FileReader();

    reader.onloadend = () => {
      const base64String = reader.result;
      setUploadData((prev) => ({ ...prev, image: base64String }));
    };
    reader.readAsDataURL(file);
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);

    if (isAuth) {
      if (uploadData.image === "") {
        onError("Please upload an image");
        setLoading(false);
        return;
      }
      axios
        .post(apiurl + "/posts", {
          image: String(uploadData.image),
          description: uploadData.description,
          user_id: user.id,
        })
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            onSuccess(res.data?.message);
            setPosts((prev) => [res.data.post, ...prev]);
            setUploadData({ description: "", image: "" });
          } else {
            onError("Failed to upload breath");
          }
        })
        .catch(() => onError("Failed to Post!!"));
    } else {
      navigate("/sign");
    }
    setLoading(false);
    onClose();
  };

  return (
    <div className="upload-breath">
      <Modal isOpen={isOpen} onClose={onClose} theme="white">
        {loading ? (
          <div className="upload-breath__load-drop">
            <Loading />
          </div>
        ) : (
          <form className="upload-breath__form" onSubmit={handleSubmit}>
            <h2 className="upload-breath__head">Post New Breath</h2>
            <input
              className="upload-breath__image-uploader"
              type="file"
              onChange={handleFileUpload}
            />
            {uploadData.image && (
              <img
                className="upload-breath__preview"
                src={String(uploadData.image)}
                alt="preview the post"
              />
            )}
            <RichTextEditor
              className="upload-breath__description"
              placeholder="Description here..."
              value={editorValue}
              onChange={handleChange}
              customControls={undefined}
              toolbarConfig={toolbarConfig as ToolbarConfig}
            />
            <div className="upload-breath__buttons-bar">
              <Button type="submit" text="post" pink disabled={loading} />
              <Button type="button" text="cancel" pink onClick={onClose} black/>
            </div>
          </form>
        )}
      </Modal>
    </div>
  );
};

export default UploadBreath;
