import React, { useState, useEffect, useContext } from "react";
import { PostType } from "./../../models/Post";
import BreathPost from "./BreathPost";
import Button from "../button/Button";
import { MessageContext } from "../../context/MessageContext";
import Loading from "../loading/Loading";
import UploadBreath from "./UploadBreath";
import axios from "axios";
import { Plus } from "lucide-react";
import { apiurl } from "../../App";

const Breath = () => {
  const [posts, setPosts] = useState<PostType[]>([]);
  const [loading, setLoading] = useState(true);
  const [openShareModal, setOpenShareModal] = useState(false);
  const { onError, onSuccess } = useContext(MessageContext);

  const onDeletePost = (id: string) => {
    axios
      .post(apiurl + "/delete-post/" + id)
      .then((res) => {
        if (res.data.error) {
          onError("Failed to delete the post");
        } else {
          const updatedPosts = posts.filter((post) => post.id !== id);
          setPosts(updatedPosts);
          onSuccess("Deleted Successfully");
        }
      })
      .catch(() => onError("Failed to delete!"));
  };

  useEffect(() => {
    fetch(apiurl + "/posts")
      .then((res) => res.json())
      .then((data) => {
        setPosts(data);
      })
      .catch((err) => console.error(err))
      .finally(() => setLoading(false));
  }, []);

  return (
    <div className="breath-page">
      <span className="breath-page__share">
        <Button
          pink
          type="button"
          text={<Plus size={56} />}
          onClick={() => {
            setOpenShareModal(true);
            window.scrollTo(0, 130);
          }}
        />
      </span>

      {loading ? (
        <Loading />
      ) : posts.length === 0 ? (
        <p className="breath-page__no-posts-message">No Posts yet</p>
      ) : (
        <div className="breath-page__posts">
          {posts
            ?.map((post) => (
              <BreathPost
                post={post}
                key={post.id}
                onDeletePost={onDeletePost}
              />
            ))
            .reverse()}
        </div>
      )}
      <UploadBreath
        isOpen={openShareModal}
        onClose={() => setOpenShareModal(false)}
        setPosts={setPosts}
      />
    </div>
  );
};

export default Breath;
