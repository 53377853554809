import React from "react";

const SelectSquare = ({classNames}) => {
  return (
    <div className={classNames}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 535 523"
        fill="none"
      >
        <path
          fill="#000"
          d="M522 512H13l-1-1V13l1-2h509l1 2v498l-1 1Zm-508-2h506V14H14v496Z"
        />
        <path fill="#fff" d="M24 1H1v23h23V1Z" />
        <path
          fill="#000"
          d="M24 25H1l-1-1V1l1-1h23l1 1v23l-1 1ZM3 23h20V3H3v20Z"
        />
        <path fill="#fff" d="M279 1h-23v23h23V1Z" />
        <path
          fill="#000"
          d="M279 25h-23l-1-1V1l1-1h23l1 1v23l-1 1Zm-22-2h20V3h-20v20Z"
        />
        <path fill="#fff" d="M279 500h-23v22h23v-22Z" />
        <path
          fill="#000"
          d="M279 523h-23l-1-1v-22l1-2h23l1 2v22l-1 1Zm-22-2h20v-20h-20v20Z"
        />
        <path fill="#fff" d="M533 1h-23v23h23V1Z" />
        <path
          fill="#000"
          d="M533 25h-23l-1-1V1l1-1h23l1 1v23l-1 1Zm-22-2h21V3h-21v20Z"
        />
        <path fill="#fff" d="M24 250H1v23h23v-23Z" />
        <path
          fill="#000"
          d="M24 274H1l-1-1v-23l1-1h23l1 1v23l-1 1Zm-21-2h20v-20H3v20Z"
        />
        <path fill="#fff" d="M533 250h-23v23h23v-23Z" />
        <path
          fill="#000"
          d="M533 274h-23l-1-1v-23l1-1h23l1 1v23l-1 1Zm-22-2h21v-20h-21v20Z"
        />
        <path fill="#fff" d="M24 500H1v22h23v-22Z" />
        <path
          fill="#000"
          d="M24 523H1l-1-1v-22l1-2h23l1 2v22l-1 1Zm-21-2h20v-20H3v20Z"
        />
        <path fill="#fff" d="M533 500h-23v22h23v-22Z" />
        <path
          fill="#000"
          d="M533 523h-23l-1-1v-22l1-2h23l1 2v22l-1 1Zm-22-2h21v-20h-21v20Z"
        />
      </svg>
    </div>
  );
};

export default SelectSquare;
