import React from "react";

const SandWatch = ({classnames}) => {
  return (
    <div className={classnames}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 180 300"
        fill="none"
      >
        <path
          fill="#fff"
          d="M76 120s50-18 49-65V20l-52-1-61-1v36c1 47 51 66 51 66v11s-50 18-49 65v35a10391 10391 0 0 0 113 1v-35c-1-47-51-66-51-66v-11Z"
        />
        <path
          fill="#000"
          d="M13 232v-1l-1-35c0-44 43-64 50-66v-9c-7-3-51-23-51-67V18l1-1 61 1h52l1 1v36c1 44-42 63-49 66v9c7 3 50 23 51 67v35a1 1 0 0 1-1 2l-52-1a12238 12238 0 0 1-62-1Zm51-113v13c-1 0-49 18-49 64v34h60l51 1-1-34c0-46-49-65-50-65l-1-1v-11l1-1c1 0 49-19 49-64l-1-34-50-1H13v4l1 30c0 45 49 65 49 65h1Z"
        />
        <path
          fill="#FC7E29"
          d="m66 111 3 1 3-1c2-1 32-12 41-40l-88-1c9 28 40 40 41 41Z"
        />
        <path
          fill="#000"
          d="M38 94c-6-6-11-14-14-24a1 1 0 0 1 1-1h88l1 1v1c-9 29-40 41-41 41l-3 1h-1l-3-1c-1 0-15-6-28-18ZM27 71c10 27 39 38 40 39h5c1-1 30-12 39-38l-84-1Z"
        />
        <path fill="#FC7E29" d="M24 200v31l93 1v-31l-93-1Z" />
        <path
          fill="#000"
          d="M23 232v-1l-1-31 2-1 93 1 1 1v31a1 1 0 0 1-1 2l-93-1-1-1Zm2-31v29l91 1-1-29-90-1Z"
        />
        <path
          fill="#FC7E29"
          d="M83 164c0 7-6 13-13 13s-13-6-13-13 13-23 13-23 13 16 13 23Z"
        />
        <path
          fill="#000"
          d="M60 174c-3-3-4-6-5-10 0-8 13-23 13-24h3c0 1 13 17 13 24 0 8-6 14-14 14-4 0-7-1-10-4Zm10-31c-4 5-12 16-12 21 0 3 1 6 4 8 2 2 5 4 8 4 6 0 11-5 11-12 0-5-7-16-11-21Z"
        />
        <path
          fill="#FF5052"
          d="M126 3 11 2c-5 0-9 3-9 8 0 4 4 8 9 8l115 2c4 0 8-4 8-9 0-4-4-8-8-8Z"
        />
        <path
          fill="#000"
          d="M4 17c-2-2-3-5-3-7C1 4 5 0 11 0l114 1c6 0 10 5 10 10 0 6-4 10-9 10L11 20c-3 0-5-2-7-3ZM131 6l-5-2L11 3c-4 0-7 3-7 7s3 7 7 7l115 1c4 0 7-3 7-7l-2-5Z"
        />
        <path
          fill="#FF5052"
          d="m128 232-115-1c-5 0-8 4-8 8 0 5 4 9 8 9l115 1c5 0 8-4 8-8 0-5-3-9-8-9Z"
        />
        <path
          fill="#000"
          d="M6 246c-1-1-3-4-3-7a9 9 0 0 1 10-9l115 1c5 0 10 5 10 10s-4 10-10 9l-115-1c-2 0-5-1-7-3Zm127-10-5-2-115-1a7 7 0 0 0-7 6c0 4 3 8 7 8l115 1c4 0 7-3 7-7l-2-5Z"
        />
      </svg>
    </div>
  );
};

export default SandWatch;
