import React from "react";

const ShopBanner = ({ classNames }) => {
  return (
    <div className={classNames}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 665" fill="none">
        <path
          fill="#FC7E29"
          d="M377 3H177C121 3 75 49 75 106v402c0 57 46 103 102 103h200c57 0 103-46 103-103V106C480 49 434 3 377 3Z"
        />
        <path
          fill="#FF5052"
          d="M355 3H156C99 3 53 49 53 106v402c0 57 46 103 103 103h199c57 0 103-46 103-103V106C458 49 412 3 355 3Z"
        />
        <path
          fill="#E22758"
          d="M339 3H140C83 3 37 49 37 106v402c0 57 46 103 103 103h199c57 0 103-46 103-103V106C442 49 396 3 339 3Z"
        />
        <path
          fill="#FFDCC5"
          d="M321 3H121C64 3 18 49 18 106v402c0 57 46 103 103 103h200c57 0 103-46 103-103V106C424 49 378 3 321 3Z"
        />
        <path
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="5"
          d="M305 3H106C49 3 3 49 3 106v402c0 57 46 103 103 103h199c57 0 103-46 103-103V106C408 49 362 3 305 3Z"
        />
        <path
          fill="#FC7E29"
          stroke="#191E32"
          strokeMiterlimit="10"
          strokeWidth="4.3"
          d="m39 322 105-108 70 72-105 107-70-71Zm76 18c9-10 9-26 0-36a25 25 0 0 0-35 0 26 26 0 0 0-1 36c10 10 26 10 36 0Zm41-54 11-12c4-4 4-9 1-12-4-3-9-3-12 0l-23 24c-4 3-4 8-1 12 4 3 9 3 12-1l12-11Zm17 18 12-12c3-4 4-9 0-12-3-3-8-3-12 0l-22 23c-4 4-4 10-1 13 4 3 8 3 12-1l11-11Z"
        />
        <path
          fill="#E22758"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="4.3"
          d="M272 254v-48c0-6 2-7 7-7h19c4 0 6 2 6 6v95c0 13-4 24-11 34-3 4-7 4-10 0-7-10-11-21-11-33v-47ZM299 188h-21v-19c0-5 6-9 11-9 5 1 10 5 10 11v17Z"
        />
        <path
          fill="#FF5052"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="4.3"
          d="m328 460 3 24 9 52c1 10-3 17-12 21l-121 51-3 2c-4 2-5 6-4 10 2 4 6 6 10 4l3-1 125-53 2-1c3-1 6 0 8 3 2 2 2 6 0 9a26 26 0 0 1 4 33c-6 8-16 13-26 11-11-3-17-11-19-26l-41 17a25 25 0 0 1 4 33c-6 8-17 12-26 10-11-3-17-11-19-25h-2c-4 2-8 4-12 4-10 3-21-4-24-14-4-10 0-22 10-27l1-1-10-14-63-83c-2-3-3-3-6-2l-23 10c-5 2-10-1-10-7 0-2 1-5 4-6l27-11c6-3 13 0 18 6a1820 1820 0 0 1 21 25l103-43 46-19c8-4 15-4 21 4l2 4Zm-165 66 19 26h3l21-9 2-1-15-27h-2l-8 3-20 8Zm112-47-28 12 12 30h1l22-10c2 0 1-1 1-2l-3-13-5-17Zm-54 58 25-11-12-29-29 12 16 28Zm76-32 22-9-5-32h-1l-23 10-1 2 3 13 5 16Zm-66 78-1-3-14-23c0-2-1-2-3-1l-19 8-2 1 18 24 4 1 17-7Zm45-19 18-8 1-2-7-28-1-1-23 9 12 30Zm-48-15 15 27 3 1 14-7 3-1-12-29-23 9Zm80 1 15-6c3-1 4-2 3-5l-4-23-1-6-19 8c-2 1-2 2-1 3l3 13 4 16Zm-54 94c5-2 8-8 5-14-2-5-8-7-13-5-6 2-8 8-6 14 2 5 8 8 14 5Zm81-34c6-2 8-8 6-14-2-5-8-8-14-6-5 3-8 9-5 14 2 6 8 8 13 6Z"
        />
        <path
          fill="#FC7E29"
          stroke="#191E32"
          strokeMiterlimit="10"
          strokeWidth="4.3"
          d="M228 137V99c0-5 1-6 3-6h5c1 0 2 2 2 5v75c0 10-1 19-4 27 0 4-2 4-2 0-3-8-4-16-4-26v-37Z"
        />
        <path
          fill="#FF5052"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="4.3"
          d="M233 87c4 0 7-3 7-7 0-5-3-8-7-8s-7 3-7 8c0 4 3 7 7 7ZM304 111c8 0 15-7 15-15s-7-15-15-15-14 7-14 15 6 15 14 15Z"
        />
        <path
          fill="#E22758"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="4.3"
          d="M278 445c8 0 14-7 14-15s-6-15-14-15-15 7-15 15 7 15 15 15Z"
        />
        <path
          fill="#FF5052"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="4.3"
          d="m249 354-31 24-24-32 32-24 23 32Z"
        />
        <path
          fill="#FC7E29"
          stroke="#191E32"
          strokeMiterlimit="10"
          strokeWidth="4.3"
          d="m225 445-22 17-17-23 22-16 17 22Z"
        />
        <path
          fill="#FF5052"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
          d="m111 126 8-2c10-3 17-9 21-18 5-7 7-15 9-24l1-9v-1l1 7c1 10 4 21 10 30 6 10 16 16 27 17h1l-6 1c-10 3-18 9-23 17-4 8-7 16-9 25l-1 11-1-7c-1-10-4-21-10-30s-15-15-26-17h-2 0Z"
        />
      </svg>
    </div>
  );
};

export default ShopBanner;
