import React from "react";

const DiscoverEye = ({classNames}) => {
  return (
    <div className={classNames}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
       viewBox="0 0 820 1080"
        fill="none"
      >
        <mask
          id="a"
          width="820"
          height="1080"
          x="0"
          y="0"
          maskUnits="userSpaceOnUse"
          style={{maskType:"luminance"}}
        >
          <path fill="#fff" d="M820 0H1v1080h819V0Z" />
        </mask>
        <g strokeMiterlimit="10" strokeWidth="4.3" mask="url(#a)">
          <path
            fill="#FFDCC5"
            stroke="#000"
            d="M785 1094c36 56 60 41 96 96l-303 117c-11-66-39-61-50-127-10-65 18-70 7-135s-39-61-49-126c-11-66 17-70 6-136-10-65-38-61-49-126s17-70 7-135c36 55 12 71 48 127 36 55 60 40 95 96 36 55 12 71 48 126 36 56 60 41 96 96 36 56 12 72 48 127Z"
          />
          <path
            fill="#FF5052"
            stroke="#000"
            d="M659 1151c24 62 50 52 74 114l-321 51c3-66-25-67-22-134 3-66 32-64 35-131 3-66-26-67-22-133 3-67 31-65 34-132 3-66-25-67-22-133 3-67 32-65 35-131 23 62-3 72 20 134 24 62 51 51 74 113 24 62-3 73 21 135 23 61 50 51 73 113 24 62-3 72 21 134Z"
          />
          <path
            fill="#FC7E29"
            stroke="#191E32"
            d="M502 1183c8 66 37 62 45 128l-324-27c19-63-8-71 11-135s46-55 65-119-9-72 10-135c19-64 46-56 65-119 19-64-8-72 11-135 19-64 46-56 65-119 8 65-20 69-12 135 8 65 36 62 44 128s-20 69-12 135 36 62 44 128c9 66-20 69-12 135Z"
          />
          <path
            fill="#E22758"
            stroke="#000"
            d="M312 1171c-11 65 17 70 6 135L15 1188c37-56 13-71 49-127 36-55 60-40 96-95 36-56 12-71 49-127 36-55 60-40 96-95 36-56 12-71 48-127 37-55 61-40 97-95-11 65-39 60-50 126-11 65 17 70 6 135s-39 61-50 126 17 70 6 136c-11 65-39 60-50 126Z"
          />
          <path
            fill="#FFDCC5"
            stroke="#000"
            d="M785-154c36-56 60-41 96-96L578-367c-11 66-39 61-50 126-10 66 18 70 7 136-11 65-39 61-49 126-11 66 17 70 6 136-10 65-38 60-49 126-11 65 17 70 7 135 36-55 12-71 48-127 36-55 60-40 95-96 36-55 12-71 48-126 36-56 60-41 96-96 36-56 12-72 48-127Z"
          />
          <path
            fill="#FF5052"
            stroke="#000"
            d="M659-211c24-62 50-52 74-114l-321-51c3 66-25 67-22 134 3 66 32 64 35 131 3 66-26 67-22 133 3 67 31 65 34 131 3 67-25 68-22 134s32 65 35 131c23-62-3-72 20-134 24-62 51-52 74-113 24-62-3-73 21-135 23-62 50-51 73-113 24-62-3-72 21-134Z"
          />
          <path
            fill="#FC7E29"
            stroke="#191E32"
            d="M502-243c8-66 37-62 45-128l-324 27c19 63-8 71 11 135s46 55 65 119-9 72 10 135c19 64 46 56 65 119 19 64-8 72 11 135 19 64 46 56 65 119 8-65-20-69-12-135s36-62 44-128-20-69-12-135 36-62 44-128c9-66-20-69-12-135Z"
          />
          <path
            fill="#E22758"
            stroke="#000"
            d="M312-231c-11-65 17-70 6-135L15-248c37 55 13 71 49 127 36 55 60 40 96 95 36 56 12 71 49 127 36 55 60 40 96 95 36 56 12 71 48 127 37 55 61 40 97 95-11-65-39-60-50-126-11-65 17-70 6-135s-39-61-50-126c-11-66 17-70 6-136-11-65-39-60-50-126Z"
          />
        </g>
        <path
        className="eye-svg"
          fill="#FC7E29"
          stroke="#191E32"
          strokeMiterlimit="10"
          strokeWidth="4.6"
          d="M591 478c-85-129-221-129-305 0l-10 14 10 14c84 129 220 129 305 0l9-14-9-14Z"
        />
        <path
        className="eye-svg"
          fill="#E22758"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="4.6"
          d="M591 481a192 192 0 0 0-305 0l-10 11 10 11a192 192 0 0 0 305 0l9-11-9-11Z"
        />
        <path
        className="eye-svg"
          fill="#FFDCC5"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="4.6"
          d="M591 484c-85-73-221-73-305 0l-10 8 10 8c84 73 220 73 305 0l9-8-9-8Z"
        />
        <path
        className="eye-svg"
          fill="#FF5052"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="4.6"
          d="M489 504a52 52 0 1 0-101-24 52 52 0 0 0 101 24Z"
        />
        <path
        className="eye-svg"
          fill="url(#b)"
          stroke="#191E32"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4.6"
          d="M469 499a32 32 0 1 0-62-14 32 32 0 0 0 62 14Z"
        />
        <path
        className="eye-svg"
          fill="#FFDCC5"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="4.6"
          d="M408 528a17 17 0 1 0-23-24 17 17 0 0 0 23 24Z"
        />
        <defs>
          <linearGradient
            id="b"
            x1="449"
            x2="420.8"
            y1="471.4"
            y2="525"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#191E32" />
            <stop offset="1" stopColor="#2A324C" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};

export default DiscoverEye;
