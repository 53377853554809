import React from "react";
import Input from "../form/input/Input";
import Button from "../button/Button";
import { getAvatar } from "../../utils/getAvatar";

interface Props {
  avatar: string;
  comment: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onPost: (value: any) => void;
  placeholder: string;
  inputRef?: React.RefObject<HTMLInputElement>;
}
const UserCommentPost = ({
  avatar,
  comment,
  placeholder,
  onChange,
  inputRef,
  onPost,
}: Props) => {
  return (
    <form className="user-comment-post-form" onSubmit={onPost}>
      <img
        className="user-comment-post__avatar"
        src={String(getAvatar(avatar))}
        alt="user avatar"
      />
      <Input
        name="user-comment"
        value={comment}
        isRequired
        onChange={onChange}
        variant="pink"
        placeholder={placeholder}
        ref={inputRef}
      />
      <Button text="Post" type="submit" pink size="x-small" />
    </form>
  );
};

export default UserCommentPost;
