import React from "react";

const CursorClicked = ({ classNames }) => {
  return (
    <div className={classNames}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 246 246" fill="none">
        <path
          fill="#FC7E29"
          d="M246 147 77 76l73 165 22-47 36 35 26-25-36-35 48-22Z"
        />
        <path
          fill="#000"
          d="m150 243-1-1L76 77l1-2h1l169 71a1 1 0 0 1 0 2l-47 21 35 34v2l-26 25h-2l-35-34-21 46-1 1ZM80 78l70 161 21-45 1-1h1l35 35 24-24-35-34v-2l47-21L80 78ZM54 54h-1L24 26v-2h2l29 29v1h-1ZM42 76h-1L1 65v-1l1-1 40 11 1 1-1 1ZM2 111l-1-1v-1l40-11 2 1-1 2-40 10ZM25 151l-1-1v-1l29-29h2v2l-29 28-1 1ZM65 174v-1l-1-1 11-39 1-1 1 2-11 39-1 1ZM136 76l-1-1 1-1 39-11 2 1-1 1-40 11ZM123 54h-1v-1l30-29h1v2l-29 28h-1ZM101 42l-1-1 11-39 1-1 1 1-11 39-1 1ZM76 42l-1-1L64 2l1-1 1 1 11 39-1 1Z"
        />
      </svg>
    </div>
  );
};

export default CursorClicked;
