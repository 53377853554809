import React, { useContext, useState } from "react";
import { PostType } from "../../models/Post";
import { getAvatar } from "../../utils/getAvatar";
import BreathPostPreview from "./BreathPostPreview";
import Modal from "../modal/Modal";
import Button from "../button/Button";
import { UserContext } from "../../context/UserContext";
import { EllipsisVertical, Heart, MessageCircleMore } from "lucide-react";
import { formatDistanceToNow } from "date-fns";

interface Props {
  post: PostType;
  onDeletePost: (id: string) => void;
}

const BreathPost = ({ post, onDeletePost }: Props) => {
  const [openPostPerview, setOpenPostPerview] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showOption, setShowOption] = useState(false);

  const { user } = useContext(UserContext);

  return (
    <div className="breath-post">
      <div className="breath-post__info-header">
        <div className="breath-post__user-info">
          <img
            className="breath-post__avatar"
            src={String(getAvatar(post.avatar))}
            alt="avatar"
          />
          <p className="breath-post__username">{post.username}</p>
        </div>

        {post?.username === user?.username && (
          <div
            className="breath-post__more"
            onMouseEnter={() => setShowOption(true)}
            onMouseLeave={() => setShowOption(false)}
          >
            <EllipsisVertical />
            {showOption && (
              <button
                type="button"
                className="breath-post__more-delete-btn"
                onClick={() => setShowDeleteModal(true)}
              >Delete</button>
            )}
          </div>
        )}
      </div>
      <img
        className="breath-post__image"
        src={post.image}
        alt="post"
        onClick={() => setOpenPostPerview(true)}
      />
      <span className="breath-post__icons">
        <Heart />
        <MessageCircleMore onClick={() => setOpenPostPerview(true)} />
        <p className="breath-post__date">
          {formatDistanceToNow(new Date(post.date), { addSuffix: true })}
        </p>
      </span>
      <p className="breath-post__likes">{post.likes} Likes</p>

      <p
        className="breath-post__description"
        onClick={() => setOpenPostPerview(true)}
        dangerouslySetInnerHTML={{
          __html: post.description?.slice(0, 140) + "...",
        }}
      />

      <Modal
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        theme="white"
      >
        <p className="breath-post__delete-modal-msg">
          Are you Sure that tou want to delete your post?
        </p>
        <div className="breath-post__delete-modal-actions">
          <Button
            text="Delete"
            onClick={() => onDeletePost(post.id)}
            pink
            type="button"
          />
          <Button
            text="cancel"
            onClick={() => setShowDeleteModal(false)}
            black
            type="button"
          />
        </div>
      </Modal>
      <BreathPostPreview
        isOpen={openPostPerview}
        onClose={() => setOpenPostPerview(false)}
        post={post}
      />
    </div>
  );
};

export default BreathPost;
