import React from "react";

import LandWaves from "../../assets/splashes/LandWaves";
import LandBows from "../../assets/splashes/LandBows";
import LandBush from "../../assets/splashes/LandBush";

import logo from "../../assets/svgs/logo.svg";
import mainLogo from "../../assets/svgs/text-logo.svg";

const Land = () => {
  return (
    <header className="home-page__land">
      <LandWaves classnames="home-page__land-waves-1" />
      <LandWaves classnames="home-page__land-waves-2" />

      <LandBows classnames="home-page__land-bows-1" />
      <LandBows classnames="home-page__land-bows-2" />

      <LandBush clasNames="home-page__land-bush-1" />
      <LandBush clasNames="home-page__land-bush-2" />

      <img
        className="home-page__land-logo-1"
        src={String(logo)}
        alt="logo art"
      />

      <img
        className="home-page__land-logo-2"
        src={String(logo)}
        alt="logo art"
      />

      <img
        className="home-page__land-main-logo"
        src={String(mainLogo)}
        alt="logo art"
      />
    </header>
  );
};

export default Land;
