import React from "react";
import { Route, Routes } from "react-router-dom";

import About from "./components/About/About";
import AdminPanel from "./components/Admin/AdminPanel";
import Blog from "./components/Blog/Blog";
import Blogs from "./components/blogs/Blogs";
import Breath from "./components/breath/Breath";
import Cart from "./components/cart/Cart";
import Category from "./components/pages/Category";
import Checkout from "./components/checkout/Checkout";
import Downloads from "./components/checkout/Downloads";
import Gallery from "./components/gallery/Gallery";
import Home from "./components/home/Home";
import NotFound from "./components/pages/NotFound";
import Product from "./components/product/Product";
import Profile from "./components/profile/Profile";
import Project from "./components/project/Project";
import Shop from "./components/shop/Shop";
import Sign from "./components/sign/Sign";

const AppRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/admin" element={<AdminPanel />} />
      <Route path="/shop" element={<Shop />} />
      <Route path="/product/:slug" element={<Product />} />
      <Route path="/blogs" element={<Blogs />} />
      <Route path="/about" element={<About />} />
      <Route path="/blog/:slug" element={<Blog />} />
      <Route path="/sign" element={<Sign />} />
      <Route path="/gallery" element={<Gallery />} />
      <Route path="*" element={<NotFound />} />
      <Route path="/cart" element={<Cart />} />
      <Route path="/project/:slug" element={<Project />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/cat/:category" element={<Category />} />
      <Route path="/breath" element={<Breath />} />
      <Route path="/checkout" element={<Checkout />} />
      <Route path="/downloads" element={<Downloads />} />
    </Routes>
  );
};

export default AppRouter;
